import React from 'react';
import Notification from '../Components/NotificationCard';
import SideBarProfile from '../Components/SideBarProfile.js';
import Notifications from '../Components/Notifications';
import ProfileBar from '../Components/ProfileBar';
import ListLoader from '../../components/ListLoader';
import { useNotificationsList } from 'profiles/queries/profileHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellSlash } from '@fortawesome/free-solid-svg-icons';
export default function NotificationList(props) {
  const notificationListQueryData = useNotificationsList();

  return (
    <>
      {/* <Navbar nav_only={true} /> */}
      <ProfileBar head="Notifications" redirectTo={'/athlete/profiles/profile'} />
      <div className="bg-gray-100 lg:flex gap-4 justify-center my-6 mx-0 md:mx-4 xl:mx-9 text-roboto">
        <div className="flex-col gap-4 hidden lg:block">
          <SideBarProfile />
          <div data-cy="sidebar_notification" className="hidden lg:block mt-4">
            <Notifications />
          </div>
        </div>
        <div
          data-cy="notification_page"
          className="mt-6 lg:mt-0 p-8 mx-3 lg:mx-0 lg:w-full pb-0 bg-white border border-gray-300 font-roboto rounded-lg "
        >
          <h2 className="text-xl text-gray-750 font-bold mb-8">Notifications</h2>
          {notificationListQueryData?.data?.length === 0 ? (
            <div className="flex flex-col justify-center items-center h-full">
              <FontAwesomeIcon
                icon={faBellSlash}
                className="mt-10 text-5xl text-gray-350 "
              ></FontAwesomeIcon>
              <div data-cy="data_empty" className="text-gray-350 text-xl font-bold my-6 mb-10">
                No Notification
              </div>
            </div>
          ) : (
            <>
              <ListLoader queryData={notificationListQueryData} queryKey="id">
                {({ item, index }) => {
                  return (
                    <Notification
                      type="invite"
                      name={item.title}
                      msg={item.subtitle}
                      time={item.created_at}
                      img={item.notification_picture}
                    />
                  );
                }}
              </ListLoader>
            </>
          )}
        </div>
      </div>
    </>
  );
}
