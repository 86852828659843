import React from 'react';
import VerifiedIcon from '../../assets/img/verified_icon.svg';
import tournamentIcon from '../../assets/img/tournament_icon.svg';
import InviteICon from '../../assets/img/invite_icon.svg';
import { getDurationFromNow } from 'constants/DateFunctions';

export default function Notification({ img, type, msg, name, time }) {
  const getNotificationIcon = () => {
    if (type === 'invite') return InviteICon;
    if (type === 'verified') return VerifiedIcon;
    if (type === 'tournament') return tournamentIcon;
  };

  return (
    <div data-cy="notification" className="flex gap-x-3.5 py-2 shadow-shadowBottom">
      <div className="md:w-8 md:h-8 w-11 h-11 rounded-full relative">
        <img
          data-cy="notification_img"
          className="md:w-8 md:h-8 md:min-w-min min-w-sm-icon w-11 h-11"
          style={{ borderRadius: '50%' }}
          src={img}
          alt="user"
        />
        <img className="absolute -bottom-2 right-0" src={getNotificationIcon(type)} alt="user" />
      </div>
      <div className=" flex-grow flex justify-between">
        <div>
          <p data-cy="notification_sender_name" className="text-sm text-gray-750">
            {name}
          </p>
          <p data-cy="notification_message" className="text-xs text-gray-650 mt-1 mb-2">
            {msg}
          </p>
          <p data-cy="notification_time" className="text-xxs text-gray-450">
            {getDurationFromNow(time)}
          </p>
        </div>
      </div>
    </div>
  );
}
