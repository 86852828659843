import React from 'react';
import Notification from './NotificationCard';
import { useHistory } from 'react-router-dom';
import ListLoader from '../../components/ListLoader';
import { useNotificationsList } from 'profiles/queries/profileHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellSlash } from '@fortawesome/free-solid-svg-icons';

export default function Notifications() {
  const history = useHistory();
  const notificationListQueryData = useNotificationsList();

  return (
    <div className="lg:block md:w-72 bg-white rounded-lg p-4 md:border border-gray-200">
      <p className="font-bold text-xl text-gray-750 mb-4">Notifications</p>
      {notificationListQueryData?.data?.length === 0 ? (
        <div className="flex flex-col justify-center items-center h-full">
          <FontAwesomeIcon
            icon={faBellSlash}
            className="mt-10 text-5xl text-gray-350 "
          ></FontAwesomeIcon>
          <div data-cy="data_empty" className="text-gray-350 text-xl font-bold my-6 mb-10">
            No Notification
          </div>
        </div>
      ) : (
        <>
          <ListLoader queryData={notificationListQueryData} queryKey="id">
            {({ item, index }) =>
              index <= 2 ? (
                <Notification
                  type="invite"
                  name={item.title}
                  msg={item.subtitle}
                  time={item.created_at}
                  img={item.notification_picture}
                />
              ) : null
            }
          </ListLoader>
          <div className="text-center mt-4">
            <div
              data-cy="notification_show_more"
              className="text-center cursor-pointer mt-4 font-semibold text-xs lg:text-sm text-gray-750"
              onClick={() => history.push(`/athlete/profiles/notifications`)}
            >
              View More
            </div>
          </div>
        </>
      )}
    </div>
  );
}
